import * as React from "react"
import Svg, { Path } from "react-native-svg"

const Facebook = ({ color = '#333' }) => (
  <Svg width="26" height="25" viewBox="0 0 26 25" fill={color} xmlns="http://www.w3.org/2000/svg">
    <Path d="M22.4737 0H3.03699C1.36057 0 0 1.33333 0 2.97619V22.0238C0 23.6667 1.36057 25 3.03699 25H22.4737C24.1501 25 25.5107 23.6667 25.5107 22.0238V2.97619C25.5107 1.33333 24.1501 0 22.4737 0ZM20.0441 8.92857H18.8293C17.5295 8.92857 17.0071 9.22619 17.0071 10.119V11.9048H20.0441L19.4367 14.881H17.0071V23.8095H13.9701V14.881H11.5406V11.9048H13.9701V10.119C13.9701 7.7381 15.1849 5.95238 17.6145 5.95238C19.376 5.95238 20.0441 6.54762 20.0441 6.54762V8.92857Z" fill="#333333" />
  </Svg>

)
export default Facebook
