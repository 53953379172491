import { StyleSheet } from "react-native";

export const createStyles = (styleguide) =>
  StyleSheet.create({
    textTeam: {
      fontFamily: "RegularItalic",
      fontSize: 24,
      alignSelf: "center",
      marginBottom: 265 / 2 + 80,
    },
  });
