import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Linkedin = ({ color = "#333" }) => (
  <Svg
    width="34"
    height="22"
    viewBox="0 0 34 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M16.6849 14.5321C16.8531 14.7003 17.1895 14.7003 17.3577 14.5321L32.7644 0.437308C32.3944 0.168196 31.9234 0 31.4188 0H2.22013C1.74918 0 1.27823 0.168196 0.908203 0.437308L16.6849 14.5321Z"
      fill="#333333"
    />
    <Path
      d="M33.5043 20.5197C33.6052 20.2842 33.6388 20.0151 33.6388 19.7796V2.22006C33.6388 2.01822 33.6052 1.81639 33.5715 1.64819L23.3789 10.9662L33.5043 20.5197Z"
      fill="#333333"
    />
    <Path
      d="M0.100917 1.58105C0.0336391 1.78289 0 2.01836 0 2.2202V19.7462C0 20.0153 0.0336391 20.2507 0.134556 20.4862L10.4281 10.8318L0.100917 1.58105Z"
      fill="#333333"
    />
    <Path
      d="M22.3028 11.9082L18.2998 15.5749C17.9297 15.9113 17.4588 16.0795 17.0215 16.0795C16.5505 16.0795 16.1132 15.9113 15.7432 15.6085L11.471 11.7737L1.00928 21.6299C1.34567 21.8654 1.74934 22 2.22028 22H31.419C31.8563 22 32.26 21.8654 32.63 21.6299L22.3028 11.9082Z"
      fill="#333333"
    />
  </Svg>
);
export default Linkedin;
